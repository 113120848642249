/* Component Dependencies */
var articleLinksGroupHelpTemplate = require('templates/articleLinksGroup-help.hbs');
var articleLinksGroupCopyRightTemplate = require('templates/articleLinksGroup-copyRight.hbs');
var AriesComponent = require('libs/aries-component');
var Cookies = require('libs/cookies');

AriesComponent.create({
  type: 'articleLinksGroup',
  template: {
    'articleLinksGroup-help': articleLinksGroupHelpTemplate,
    'articleLinksGroup-copyRight': articleLinksGroupCopyRightTemplate
  },
  bindEvents: function () {
    // Update session id and prod site id in global footer component
    this.$el.find('.js-session-detail').html(Cookies.readCookie('MI_SITE') + ',' + Cookies.readCookie('sessionID'));
  }
});
